<template>
  <div style="width: 100%" class="com-list-container">
    <el-table
      :row-style="{ height: '75px' }"
      :header-row-style="{ height: '50px' }"
      :header-cell-style="{
        fontSize: '16px',
        color: '#696B6F',
        fontFamily: 'PingFangSC-Medium, PingFang SC;',
      }"
      :data="list"
      :row-class-name="tableRowClassName"
      :border="false"
      style="width: 100%"
      @select="select"
      @select-all="select"
    >
      <el-table-column
        v-if="selection"
        type="selection"
        align="right"
        width="50"
      >
      </el-table-column>
      <el-table-column label="序号" align="center" width="70">
        <template slot-scope="scope">
          <div v-if="ranking && scope.$index + startIndex === 1">
            <img style="width: 30px" src="../../assets/home/pm1.png" />
          </div>
          <div v-else-if="ranking && scope.$index + startIndex === 2">
            <img style="width: 30px" src="../../assets/home/pm2.png" />
          </div>
          <div v-else-if="ranking && scope.$index + startIndex === 3">
            <img style="width: 30px" src="../../assets/home/pm3.png" />
          </div>
          <div v-else>{{ scope.$index + startIndex }}</div>
        </template>
      </el-table-column>

      <el-table-column v-if="image" prop="image" width="80">
        <template slot-scope="scope">
          <div style="width: 50px; height: 50px; display: flex">
            <img :src="scope.row.image" style="width: 100%" />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="歌曲">
        <template slot-scope="scope">
          <div style="display: flex; align-items: center">
            <div
              v-if="
                ranking && scope.$index + startIndex <= 3 && scope.row.image
              "
              style="
                width: 50px;
                height: 50px;
                display: flex;
                margin-right: 10px;
              "
            >
              <img style="width: 100%" :src="scope.row.image" />
            </div>
            <div
              class="hover"
              @click="$router.push(`/singleDetail/${scope.row.id}`)"
            >
              {{ scope.row.name }}
            </div>
            <!--            <div >{{ scope.row.name }}</div>-->
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="singer" label="歌手"> </el-table-column>

      <el-table-column v-if="songs" label="歌单">
        <template slot-scope="scope">
          {{ scope.row.songs ? scope.row.songs : "未分配" }}
        </template>
      </el-table-column>
      <el-table-column prop="type" v-if="type" label="类型"> </el-table-column>
      <el-table-column width="30">
        <template slot-scope="scope">
          <div class="table-play" @click="play(scope.row)">
            <img src="../../assets/home/3.png" />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="duration" width="110" label="时长">
        <template slot-scope="scope">
          {{ scope.row.duration | formatSeconds }}
        </template>
      </el-table-column>
    </el-table>
    <com-page
      :total="total"
      layout="prev, pager, next"
      :page-size="pageSize"
      :currentPage="currentPage"
      @change="pageChange"
    ></com-page>
  </div>
</template>
<script>
import comPage from "@/components/com/com-page";
import { formatSeconds } from "@/mixin/date";
export default {
  mixins: [formatSeconds],
  components: {
    comPage,
  },
  data() {
    return {
      // currentPage: 1,
    };
  },
  computed: {
    startIndex() {
      let { pageSize, currentPage } = this;
      return 1 + pageSize * (currentPage - 1);
    },
    currentList() {
      return this.list.slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize
      );
    },
  },
  props: {
    songs: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Boolean,
      default: false,
    },
    image: {
      type: Boolean,
      default: false,
    },
    list: Array,
    ranking: {
      type: Boolean,
      default: false,
    },
    selection: {
      type: Boolean,
      default: false,
    },
    pageSize: {
      type: Number,
      default: 30,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    total: Number,
  },
  methods: {
    tableRowClassName({ rowIndex }) {
      return rowIndex % 2 ? "table-bg1" : "table-bg2";
    },
    pageChange(currentPage) {
      this.$emit("currentPageChange", currentPage);
    },
    play(item) {
      item.play(this);
    },
    select(select, row) {
      this.$emit("select", select, row);
    },
  },
};
</script>
<style lang="scss">
@import "../../style/list";
.com-list-container {
  @extend .com-list;
  min-height: 300px;
}
</style>
